<template>
  <div>
    <b-row class="mb-1" v-if="canAddBusiness">
      <b-col sm="12">
        <b-button @click="add()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Empresa">
          <i class="fa fa-plus"></i> Agregar
        </b-button>
      </b-col>
    </b-row>

    <b-row class="mb-3">
      <b-col>
        <b-table class="mb-0"
                responsive="sm"
                head-variant="dark"
                :hover="true"
                :small="true"
                :fixed="false"
                :items="table.items"
                :fields="table.fields"
                :filter="table.filter"
                :current-page="table.currentPage"
                :per-page="table.perPage"
                v-if="table.items.length">

            <template v-slot:cell(id)="data">
              <b>{{ data.item.id }}</b>
            </template>

            <template v-slot:cell(name)="data">              
              {{ data.item.name }}              
            </template>

            <template v-slot:cell(fiscal_conditions)="data">
              <div v-if="data.item.fiscal_condition">
                {{ data.item.fiscal_condition.name }}
              </div>
            </template>            

            <template v-slot:cell(types_documents)="data">
              <div v-if="data.item.type_document">
                {{ data.item.type_document.name }}: {{data.item.documents_number}}
              </div>
            </template>                        
                                
            <template v-slot:cell(f_action)="data">
              <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">                                                                
                <b-dropdown-header>Acciones</b-dropdown-header>                                                           
                
                <b-dropdown-item @click="edit(data.item)">
                  <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                </b-dropdown-item>
                <b-dropdown-item @click="remove(data.item)">
                  <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                </b-dropdown-item>
              </b-dropdown>
            </template>
        </b-table>
        <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
          <i class="fa fa-angle-double-left"></i>
          Volver
        </b-button>
      </b-col>

      <b-col>
        <nav>
          <b-pagination class="pull-right mb-0"
                          size="sm"
                          pills=""
                          :total-rows="getRowCount(table.items)"
                          :per-page="table.perPage"
                          v-model="table.currentPage" />
        </nav>
      </b-col> 
    </b-row>              

    <!-- ########################### -->
    <!-- #####     MODALES     ##### -->
    <!-- ########################### -->

    <b-modal v-model="modal.form.active"
             size="xl"
             header-bg-variant="dark"
             header-text-variant="white">

      <div slot="modal-header">
        {{this.modal.form.title}}
      </div>

      <b-row>
        <b-col lg="12">
          <b-card no-body>
            <b-tabs pills card>
              <b-tab title="General" active>              
                <b-row>
                  <b-col lg="3">
                    <b-row>
                      <b-col lg="12">
                        <b-form-group label="Referencia">
                          <b-form-input type="text"
                                        size="sm"
                                        v-model="crud.form.reference">
                          </b-form-input>           
                        </b-form-group>                    
                      </b-col>
                      <b-col lg="12">
                        <b-form-group label="Logo">
                          <Imagen :images="crud.form.image" @load-image="crud.form.image = $event" typeImage="Logo"/>
                        </b-form-group>  
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col lg="9">
                    <b-row>
                      <b-col lg="6">
                        <b-form-group label="Razón Social">
                          <b-form-input type="text"
                                        size="sm"
                                        v-model="crud.form.name">
                          </b-form-input>           
                        </b-form-group>
                      </b-col>

                      <b-col lg="6">
                        <FindObject render="search"
                                    type="erpFiscalConditions" 
                                    @select-object="loadFiscalConditions($event)" 
                                    :valueID="crud.form.fiscal_conditions_id"                                        
                                    :key="componentKey"/>   
                      </b-col>

                      <b-col lg="6">
                        <FindObject render="search"
                                    type="erpTypeDocuments" 
                                    @select-object="loadTypeDocuments($event)" 
                                    :valueID="crud.form.types_documents_id"
                                    :key="componentKey"/>  
                      </b-col>
                              
                      <b-col lg="6">
                        <b-form-group label="Número Documento">
                          <b-form-input type="text"
                                        size="sm"
                                        v-model="crud.form.documents_number"
                                        @change="validDocumentNumber()">
                          </b-form-input>
                        </b-form-group>
                      </b-col>
                              
                      <b-col lg="6">
                        <b-form-group label="Email">
                          <b-form-input type="text"
                                        size="sm"
                                        v-model="crud.form.email">
                          </b-form-input>
                        </b-form-group>
                      </b-col>
                                
                      <b-col lg="6">
                        <b-form-group label="Teléfono">
                          <b-form-input type="text"
                                        size="sm"
                                        v-model="crud.form.phone">
                          </b-form-input>
                        </b-form-group>
                      </b-col>
                              
                      <b-col lg="6">
                        <b-form-group label="Inicio de Actividades">
                          <b-form-input type="date"
                                        size="sm"
                                        v-model="crud.form.date_start_activities">
                          </b-form-input>
                        </b-form-group>
                      </b-col>
                      
                      <b-col lg="6">
                        <b-form-group label="Ingresos Brutos">
                          <b-form-input type="text"
                                        size="sm"
                                        v-model="crud.form.gross_receipts">
                          </b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>              
              </b-tab>
              <b-tab title="Datos Bancarios">
                <b-row>
                  <b-col lg="6">
                    <b-form-group label="CBU">
                      <b-form-input type="text"
                                    size="sm"
                                    v-model="crud.form.cbu">
                      </b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col lg="6">
                    <b-form-group label="Alias">
                      <b-form-input type="text"
                                    size="sm"
                                    v-model="crud.form.alias">
                      </b-form-input>
                    </b-form-group>
                  </b-col>                    
                </b-row>              
              </b-tab>
              <b-tab title="Ubicación">
                <b-row>
                  <b-col lg="8">
                    <b-form-group label="Dirección">
                      <b-form-input type="text"
                                    size="sm"
                                    v-model="crud.form.address">
                      </b-form-input>           
                    </b-form-group>
                  </b-col>
                          
                  <b-col lg="4">
                    <b-form-group label="Código Postal">
                      <b-form-input type="text"
                                    size="sm"
                                    v-model="crud.form.postal_code">
                      </b-form-input>           
                    </b-form-group>
                  </b-col>

                  <b-col lg="4">
                    <b-form-group label="Ciudad">
                      <b-form-input type="text"
                                    size="sm"
                                    v-model="crud.form.city">
                      </b-form-input>           
                    </b-form-group>
                  </b-col>

                  <b-col lg="4">
                    <b-form-group label="Provincia">
                      <b-form-input type="text"
                                    size="sm"
                                    v-model="crud.form.state">
                      </b-form-input>           
                    </b-form-group>
                  </b-col>

                  <b-col lg="4">
                    <b-form-group label="País">
                      <b-form-input type="text"
                                    size="sm"
                                    v-model="crud.form.country">
                      </b-form-input>           
                    </b-form-group>
                  </b-col>
                </b-row>              
              </b-tab>
              <b-tab title="Presupuesto" v-if="showBudget">
                <b-row>
                  <b-col lg="12">
                    <b-form-group label="Descripción" description="Se utiliza para mostrar una breve información de la empresa en el presupuesto.">
                      <vue-editor class="display-bussiness-ql-editor" v-model="crud.form.description"></vue-editor>              
                    </b-form-group>                    
                  </b-col>
                </b-row>                
              </b-tab>
            </b-tabs>              
          </b-card>
        </b-col>
      </b-row>
        
      <div slot="modal-footer">
        <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
        <b-button variant="dark" @click="save()">Guardar</b-button>          
      </div>
    </b-modal>        
  </div>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper'    
  import Param from '@/config/parameters'  
  import FindObject from '@/components/inc/find/findObject'  
  import Imagen from '@/components/inc/image/image'
  import { VueEditor } from "vue2-editor";
  import Funciones from '@/handler/funciones'

  export default {
    components: {
      FindObject,
      Imagen,
      VueEditor,
    },
    data() {
      return {
        parameters: {
          enableMultibusiness: Helper.hasParametersAccess(Param.P58),
        },
        table : {
          items: [],
          fields: [
            {key: 'id', label: 'ID', sortable: true, class:"align-middle"},
            {key: 'reference', label: 'Referencia', class:"align-middle"},
            {key: 'name', label: 'Nombre', class:"align-middle"},
            {key: 'types_documents', label: 'Documento', class:"align-middle"},     
            {key: 'fiscal_conditions', label: 'Condicion Fiscal', class:"align-middle"},                                       
            {key: 'f_action', label:'', class:"align-middle"},
          ],                    
          currentPage: 1,
          perPage: 50,
        },        
        crud: {
          form: {
            id: 0,
            reference: '',
            name: '',
            address: '',
            city: '',
            postal_code: '',
            state: '',
            country: '',
            fiscal_conditions: null,
            fiscal_conditions_id: 0,
            types_documents: null,
            types_documents_id: 0,
            documents_number: '',
            date_start_activities: '',
            gross_receipts: '',
            cbu: '',
            alias: '',
            phone: '',
            email: '',
            image: [],
            description: '',
          }
        },
        modal: {
          form: {
            active: false,
            title: ''
          },
        },
        componentKey: 0,
      }
    },
    mounted(){  
      this.load()          
    },
    computed: {
      canAddBusiness() {
        return this.parameters.enableMultibusiness
      },
      showBudget() {
        var status = false
        var modules = Session.getSession().auth.user.permissions.modules        
        modules.forEach(element => {
          if(element.id == Modules.PRESUPUESTOS) {                             
            status = true                       
          }
        })        
        return status
      },      
    },    
    methods: {   
      getRowCount (items) {
        return items.length
      },       
      load() {        
        var result = serviceAPI.obtenerEmpresas()

        result.then((response) => {
          var data = response.data
          this.table.items = data
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error))
        });  
      },                    

      add() {        
        this.crud.form.id = 0
        this.crud.form.reference = ''
        this.crud.form.name = ''
        this.crud.form.address = ''
        this.crud.form.city = ''
        this.crud.form.postal_code = ''
        this.crud.form.state = ''
        this.crud.form.country = ''
        this.crud.form.fiscal_conditions = null
        this.crud.form.fiscal_conditions_id = 0
        this.crud.form.types_documents = null
        this.crud.form.types_documents_id = 0
        this.crud.form.documents_number = ''
        this.crud.form.date_start_activities = ''
        this.crud.form.gross_receipts = ''
        this.crud.form.cbu = ''
        this.crud.form.alias = ''
        this.crud.form.phone = ''
        this.crud.form.email = ''
        this.crud.form.image = []
        this.crud.form.description = ''

        this.modal.form.title = "Nueva Empresa"
        this.modal.form.active = true
      },
      edit(item) {                          
        this.crud.form.id = item.id
        this.crud.form.reference = item.reference
        this.crud.form.name = item.name
        this.crud.form.address = item.address
        this.crud.form.city = item.city
        this.crud.form.postal_code = item.postal_code
        this.crud.form.state = item.state
        this.crud.form.country = item.country
        this.crud.form.documents_number = item.documents_number

        this.crud.form.fiscal_conditions = item.fiscal_conditions
        this.crud.form.fiscal_conditions_id = item.fiscal_conditions_id
        
        this.crud.form.types_documents = item.types_documents
        this.crud.form.types_documents_id = item.types_documents_id

        if(!item.date_start_activities){
          this.crud.form.date_start_activities = ''
        } else {
          this.crud.form.date_start_activities = item.date_start_activities
        }

        this.crud.form.gross_receipts = item.gross_receipts
        this.crud.form.cbu = item.cbu
        this.crud.form.alias = item.alias
        this.crud.form.phone = item.phone
        this.crud.form.email = item.email          

        this.crud.form.image = []    
        if(Array.isArray(item.image)) {
          this.crud.form.image = item.image
        } else {
          if(item.image!= undefined) {
            if(item.image.length > 0) {
              this.crud.form.image = [{ path: item.image }]
            } else {
              this.crud.form.image = []
            }
          }
        }

        this.crud.form.description = item.description

        this.forceRerender()

        this.modal.form.title = "Editar Empresa"
        this.modal.form.active = true
      },      

      loadFiscalConditions (object) {
        if(object){
          this.crud.form.fiscal_conditions = object
          this.crud.form.fiscal_conditions_id = object.id             
        } else {
          this.crud.form.fiscal_conditions = null
          this.crud.form.fiscal_conditions_id = 0
        }
      },
      loadTypeDocuments (object) {
        if(object){
          this.crud.form.types_documents = object
          this.crud.form.types_documents_id = object.id             
        } else {
          this.crud.form.types_documents = null
          this.crud.form.types_documents_id = 0
        }
      },

      save() {
        if(this.crud.form.email) {
          var expreg = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$")
          if (!expreg.test(this.crud.form.email)) {          
            this.$awn.alert("El email no posee un formato válido")          
            return false
          }       
        }

        let _msj = "¿Confirmar la creación de una nueva empresa?"
        let _title = "Nueva empresa"
        let _button = "CREAR"
        if(this.crud.form.id) {
          _msj = "¿Editar configuración de la empresa?"
          _title = "Editar empresa"          
          _button = "GUARDAR"
        }

        this.$bvModal.msgBoxConfirm(_msj, {
          title: _title,
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: _button,
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {          
            let loader = this.$loading.show();                
            
            var image = new FormData();
            this.crud.form.image.forEach((value, key) => {
              image.append(key, value.path);
            })
            
            var result = null
            if(this.crud.form.id) {
              result = serviceAPI.editarEmpresa(JSON.stringify(this.crud.form), image);
            } else {
              result = serviceAPI.agregarEmpresa(JSON.stringify(this.crud.form), image);
            }

            result.then((response) => {                    
              this.modal.form.active = false                                        
              loader.hide()                    
              this.load()

              this.$awn.success("Datos guardados con éxito");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })             
      },
      remove(item) {
        this.crud.form.id = item.id
        this.crud.form.name = item.name          

        this.$bvModal.msgBoxConfirm('¿Desea borrar la empresa (' + this.crud.form.id + ') - '+ this.crud.form.name + '?', {
          title: 'Borrar Empresa',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarEmpresa(this.crud.form.id);

            result.then((response) => {      
              this.modal.form.active = false        
              loader.hide()
              this.load()
              
              this.$awn.success("Empresa eliminada");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },      

      forceRerender() {
        this.componentKey += 1;  
      },

      validDocumentNumber() {
        this.crud.form.documents_number = this.crud.form.documents_number.replace(/\D/g, '')
        
        if(this.crud.form.types_documents.name == 'CUIT') {
          if(!Funciones.validarCUIT(this.crud.form.documents_number)) {
            this.$awn.alert("El CUIT es inválido");   
            this.crud.form.documents_number = ''           
          }            
        }
      }      
    }
  }
</script>
<style scoped>
  .card-header {
    padding: 1px;   
    padding-left: 5px;   
    font-weight: bold;     
  }
</style>
<style>
  .display-bussiness-ql-editor .ql-editor {
    min-height: 250px !important;
  }  
</style>